import React, { useEffect, useState } from 'react'
import logo from '../../images/logo/logo_inverse.png'
import Scrollspy from "react-scrollspy"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import Scroll from "../Scroll"
import "./Header.scss"

const Header = () => {
    const url = typeof window !== "undefined" ? window.location.href : "";
    const isNews = url.includes("/news") ? true :  url.includes("/helios") ? true : url.includes("/job") ? true : false;
    
    const createNavLink = (identifier, offset = -81) => {
        if (!isNews) {
            return (
                <Scroll type="id" element={identifier} offset={offset}>
                    <a className="nav-link" href={`#${identifier}`}>
                        <FormattedMessage id={`nav.${identifier}`} />
                    </a>
                </Scroll>
            )
        } else {
            return (
                <Link to={`/#${identifier}`} className="nav-link">
                    <FormattedMessage id={`nav.${identifier}`} />
                </Link>
            )
        }
    }

    const [scroll, setScroll] = useState(1)
    const [isOpened, setOpen] = useState(false)

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY < 100
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        })
    });

    const toggleNav = () => {
        setOpen(!isOpened);
    };

    return (
        <header>
            <nav className="navbar navbar-expand-lg fixed-top nav-black" id="mainNav">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" />
                    </Link>
                    <button className="navbar-toggler" onClick={toggleNav} type="button" data-bs-toggle="collapse" data-bs-target="#navBarContent" aria-controls="navBarContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars"></i>
                    </button>
                    <div className={`${isOpened ? "" : "collapse "}navbar-collapse`}  id="navBarContent">
                        <Scrollspy
                            className="navbar-nav ml-auto order-0"
                            items={["home", "about", "inuvio", "implementation", "reference", "jobs", "team", "contact-section"]}
                            currentClassName="active"
                            offset={-111}>
                            <li className="nav-item">
                                {createNavLink("home")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("about")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("inuvio")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("implementation")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("heliosxshoptet")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("news")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("team")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("reference")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("jobs")}
                            </li>
                            <li className="nav-item">
                                {createNavLink("contact-section")}
                            </li>
                        </Scrollspy>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header