/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { injectIntl } from "gatsby-plugin-intl"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import CookieConsent from "react-cookie-consent";
import { Modal } from "react-bootstrap";

const cookieStorage = {
  getItem: (item) => {
      const cookies = document.cookie
          .split(';')
          .map(cookie => cookie.split('='))
          .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[item];
  },
  setItem: (item, value) => {
      document.cookie = `${item}=${value};`
  }
}

const storageType = cookieStorage;
const consentPropertyName = 'CookieConsent';

const shoulShowPopup = () => !storageType.getItem(consentPropertyName);
const getApprovalValue = () => storageType.getItem(consentPropertyName);

const setCookies = (value) => storageType.setItem(consentPropertyName, value);

const Layout = ({ children, intl }) => {
  const [generateLeady, setGenerateLeady] = useState(false);
  const [showBanner, setShowBanner] = useState("hidden");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [optimalization, setOpt] = useState(true);
  const [functionality, setFnc] = useState(true);
  const [tracking, setTra] = useState(true);

  const acceptCookies = () => {
    setGenerateLeady(true);
    setShowBanner("hidden");
  }

  const modalAccept = () => {
    setCookies(true);
    acceptCookies();
    toggleModal();
  }

  const saveOptions = () => {
    if (!optimalization && !functionality && !tracking) {
      setCookies(false);
      setShowBanner("hidden");
      toggleModal();
    } else {
      modalAccept();
    }
  }

  const toggleModal = () => { setIsOpen(!modalIsOpen) }

  useEffect(() => {
    if (shoulShowPopup()) {
      setShowBanner("show");
    } else {
      if (getApprovalValue() === "true") {
        setGenerateLeady(true);
      }
    }
  }, []);

  return (
    <div className="content-container">
      <Helmet>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/css/flag-icon.min.css" rel="stylesheet"/>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
        <script type="text/javascript">{generateLeady === true ? `
          var leady_track_key="QxM1vMvGpOiaDyma";
          (function(){
            var l=document.createElement("script");l.type="text/javascript";l.async=true;
            l.src='https://ct.leady.com/'+leady_track_key+"/L.js";
            var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(l,s);
            })();
          ` : false}
        </script>
        <script type="text/javascript">{generateLeady === true ? `
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        
          ga('create', 'UA-217169353-1', 'auto');
          ga('send', 'pageview');
          ` : false}
        </script>
      </Helmet>
      <Header siteTitle={intl.formatMessage({ id: "title" })} intl={intl}/>
      <Modal id="cookieModal" show={modalIsOpen} onHide={toggleModal} animation={false}>
          <Modal.Header closeButton>
              <div className="text-center">
                  <h6 className="h3 mb-1 text-center">
                      Nastavení cookies
                  </h6>
              </div>
          </Modal.Header>
          <Modal.Body>
            <div className='cookie-modal'>
              <div className="mb-3" style={{textAlign: "justify"}}>
                <p>
                  Když navštívíte jakoukoli webovou stránku, může ukládat nebo načítat informace ve vašem prohlížeči, většinou ve formě souborů cookie. Tyto informace se mohou týkat vás, vašich preferencí nebo vašeho zařízení a většinou se používají k tomu, aby web fungoval tak, jak očekáváte. Tyto informace vás obvykle přímo neidentifikují, ale mohou vám poskytnout personalizovanější web. Protože respektujeme vaše právo na soukromí, můžete se rozhodnout nepovolit některé typy souborů cookie. Ohledně jednotlivých možností se dozvíte v sekci gdrp a cookies. Blokování některých 
                  typů souborů cookie však může ovlivnit vaši zkušenost s webem a službami, které jsme schopni nabídnout. <a href="/news">Zásady cookies</a>
                </p>
              </div>
              <button type="submit" className="btn btn-outline-primary accept-all-button" onClick={() => modalAccept()}>
                  Přijmout vše
              </button>
              <div className="mb-3" style={{justifyContent: "center"}}>
                <div className="form-check form-switch">
                  <input className="form-check-input custom-switch" type="checkbox" role="switch" id="flexSwitchCheckCheckedDisabled" checked disabled/>
                  <label className="form-check-label" for="flexSwitchCheckCheckedDisabled">Potřebné cookies</label>
                </div>
                <div className="form-check form-switch">
                  <label className="form-check-label" for="">Optimalizační cookies</label>
                  <input className="form-check-input custom-switch" onChange={(e) => setOpt(e.target.checked)} type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={optimalization}/>
                </div>
                <div className="form-check form-switch">
                  <label className="form-check-label" for="flexSwitchCheckDefault">Funkcionální cookies</label>
                  <input className="form-check-input custom-switch" onChange={(e) => setFnc(e.target.checked)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={functionality}/>
                </div>
                <div className="form-check form-switch">
                  <label className="form-check-label" for="flexSwitchCheckDefault">Sledovací cookies</label>
                  <input className="form-check-input custom-switch" onChange={(e) => setTra(e.target.checked)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={tracking}/>
                </div>
              </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
              <button type="submit" className="btn btn-outline-primary accept-all-button" onClick={() => saveOptions()}>
                  Uložit nastavení
              </button>
          </Modal.Footer>
      </Modal>
      <CookieConsent
        visible={showBanner}
        location="bottom"
        buttonClasses="btn btn-primary"
        containerClasses="alert col-lg-12"
        overlay
        style={{
          background: "#27251F",
          textShadow: "2px 2px black",
        }}
        buttonStyle={{
          background: "#FF9E1B",
          color: "white",
          fontWeight: "bolder",
          
        }}
        buttonText="Přijmout"
        onAccept={() => acceptCookies()}
      >
        <FormattedMessage id={`cookies.text`}/>
        <a style={{color: "#FF9E1B"}} href="/gdprandcookies" target="_blank">
          <FormattedMessage id={`cookies.link`}/> 
        </a>
        <button type="submit" className="btn" onClick={() => toggleModal()} style={{color: "white"}}>
          Možnosti
        </button>
      </CookieConsent>
      <div className="main">
        {children}
      </div>
      <Footer/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)